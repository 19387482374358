import React from 'react';
import ChangeTerms from '../assets/images/Terms/ChangeTerms.svg'
import Contact from '../assets/images/Terms/Contact.svg'
import DataPrivacy from '../assets/images/Terms/DataPrivacy.svg'
import Governing from '../assets/images/Terms/Governing.svg'
import Intellectual from '../assets/images/Terms/Intellectual.svg'
import Privacy from '../assets/images/Terms/Privacy.svg'
import Profile from '../assets/images/Terms/Profile.svg'
import Termination from '../assets/images/Terms/Termination.svg'
import Usage from '../assets/images/Terms/Usage.svg'


const TermsOfUse = () => {
  return (
    
<>
<div className="bg-gray-100 min-h-screen flex justify-center items-center">
<div className="bg-white max-w-4xl w-full rounded-lg shadow-lg p-2">
        <div className="bg-teal-700 text-white text-center  rounded-t-lg">
        <img src ={Privacy} className='w-full' />
        </div>
        <div className="p-6">
          <p className="mb-6 text-gray-600">
            Welcome to the Airhub Partner Portal. <br></br>By using our services, you agree to comply with these <strong>Terms of Use</strong>.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
            <div className="p-4 border rounded-lg bg-gray-100">
            <div className='flex mr-2'>            <img src={Profile} alt ='message'/>


              <h2 className="text-lg font-bold mb-2 text-[#1a5659] m-2">Eligibility and Account:</h2>
              </div>
              <ul className="text-sm text-gray-700">
                <li>- Only authorized business partners may use this portal.</li>
                <li>- Maintain the confidentiality of your login credentials and account.</li>
              </ul>
            </div>

            <div className="p-4 border rounded-lg bg-gray-100">
            <div className='flex mr-2'>            <img src={Usage} alt ='message'/>


              <h2 className="text-lg font-bold mb-2 text-[#1a5659] m-2">Usage Guidelines:</h2>
              </div>
              <ul className="text-sm text-gray-700">
                <li>- Use the portal solely for business purposes related to the sale and management of Airhub eSIMs.</li>
                <li>- Do not engage in unlawful activities or violate our policies.</li>
              </ul>
            </div>

            <div className="p-4 border rounded-lg bg-gray-100">
            <div className='flex mr-2'>            <img src={Intellectual} alt ='message'/>


              <h2 className="text-lg font-bold mb-2 text-[#1a5659] m-2">Intellectual Property:</h2>
              </div>
              <ul className="text-sm text-gray-700">
                <li>- All content on the portal is owned by Airhub and protected by copyright laws.</li>
                <li>- You may not reproduce or use our content without permission.</li>
              </ul>
            </div>

            <div className="p-4 border rounded-lg bg-gray-100">
            <div className='flex mr-2'>            <img src={DataPrivacy} alt ='message'/>


              <h2 className="text-lg font-bold mb-2 text-[#1a5659] m-2">Data Privacy:</h2>
              </div>
              <ul className="text-sm text-gray-700">
                <li>- We collect and use your data in accordance with our Privacy Policy.</li>
                <li>- We implement security measures to protect your information.</li>
              </ul>
            </div>

            <div className="p-4 border rounded-lg bg-gray-100">
            <div className='flex mr-2'>            <img src={Termination} alt ='message'/>


              <h2 className="text-lg font-bold mb-2 text-[#1a5659] m-2">Termination:</h2>
                            </div>

              <ul className="text-sm text-gray-700">
                <li>- Airhub reserves the right to terminate your access for violations of these terms or other misconduct.</li>
              </ul>

            </div>

            <div className="p-4 border rounded-lg bg-gray-100">
            <div className='flex mr-2'>            <img src={Governing} alt ='message'/>


              <h2 className="text-lg font-bold mb-2 text-[#1a5659] m-2">Governing Law:</h2>
              </div>
              <ul className="text-sm text-gray-700">
                <li>- These terms are governed by the laws of [USA/CA].</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mt-6 space-y-4">
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200">
          <div className='flex mr-2'>            <img src={DataPrivacy} alt ='message'/>

            <h3 className="text-lg font-bold mb-2 text-[#1a5659] m-2">Data Security:</h3>
            </div>
            <p>We use industry-standard security measures to protect your information.</p>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200">
          <div className='flex mr-2'>            <img src={Profile} alt ='message'/>

            <h3 className="text-lg font-bold mb-2 text-[#1a5659] m-2">Cookies and Tracking:</h3>
          </div>
            <p>We use cookies to improve your experience on the portal. You can manage cookie settings in your browser.</p>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200">
          <div className='flex mr-2'>            <img src={ChangeTerms} alt ='message'/>

            <h3 className="text-lg font-bold mb-2 text-[#1a5659] m-2">Changes to Terms:</h3>
            </div>
            <p>We may update this policy periodically. Continued use of the portal indicates acceptance of the new policy.</p>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200">
          <div className='flex mr-2'>            <img src={Contact} alt ='message'/>

            <h3 className="text-lg font-bold mb-2 text-[#1a5659] m-2">Contact Information:</h3>
            </div>
            <p>For questions about these policies, contact us at <strong>support@airhubapp.com</strong></p>
          </div>
        </div>
      </div>
    </div>

</>
  );
};

export default TermsOfUse;
