import React, { useState } from 'react';
import axios from 'axios';
import lock from '../assets/images/lock.svg';
import tick from '../assets/images/tick.svg';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true); // To track password validity
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    console.log('Email submitted:', email);

    try {
      const response = await axios.post(`${apiUrl}ESIM/verify_partneremail`, { email });
      if (response.data.isSuccess) {
        setStep(3);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Error verifying email:', error);
      alert('This email ID is not registered with us. Please enter a valid email ID');
    }
  };

  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const validatePassword = (password) => {
    const passwordPattern =
      /^(?=.*[!@#\$%\^&\*])(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;
     return passwordPattern.test(password);
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    const isPasswordValid = validatePassword(password);
    setPasswordValid(isPasswordValid);
  
    if (isPasswordValid && password === confirmPassword) {
      // Clear any existing password errors when password is valid
      setErrors((prev) => ({ ...prev, password: '' }));
  
      try {
        const response = await axios.post(`${apiUrl}ESIM/UpdatePassword`, {
          password,
          otp: otp.join(''),
        });
        if (response.data.isSuccess) {
          alert('Password reset successful');
          setStep(4);
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.error('Error updating password:', error);
        alert('There was an error updating your password. Please try again.');
      }
    } else if (!isPasswordValid) {
      // Set password validation error message
      setErrors((prev) => ({
        ...prev,
        password:
          'Password must be at least 8 characters long, contain a number, an uppercase letter, a lowercase letter, and a special character.',
      }));
    } else {
      setPasswordMatch(false);
      alert('Passwords do not match');
    }
  };
  
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordMatch(e.target.value === password);
  };

  const handleLogin = () => {
    navigate('/');
  };

  return (
    <div className="login bg-daefef h-screen w-full flex justify-center items-center">
      <div className="rounded-2xl mb-12 md:mb-0 md:w-8/12 lg:w-5/12 xl:w-5/12 xl:p-6 dark:bg-gray-800 dark:border-gray-700 p-8 m-8" style={{ backgroundColor: "#ffffff" }}>
        <div className="text-center">
          <div className="text-5xl mb-4 flex justify-center">
            <img className="fas fa-lock" src={step === 4 ? tick : lock} alt="Lock Icon" />
          </div>
          <h2 className="font-bold mb-4 text-[2rem]">
            {step === 1 && 'Forgot Password'}
            {step === 2 && 'Verification'}
            {step === 3 && 'Verification and Set New Password'}
            {step === 4 && 'Password Reset Successful'}
          </h2>
          <p className="mb-6 text-3xl font-semibold text-gray-600">
            {step === 1 && 'Oops. It happens to the best of us. Input your email address to fix the issue.'}
            {step === 2 && 'Enter the verification code to verify this account recovery.'}
            {step === 3 && 'Enter the verification code to verify account recovery. Set your new password'}
            {step === 4 && 'Your password has been reset successfully. Return to the login screen.'}
          </p>
        </div>

        {step === 1 && (
          <form onSubmit={handleEmailSubmit}>
            <div className="mb-4">
              <fieldset className="border border-gray-300 rounded-lg">
                <legend className="text-lg font-semibold ml-4">Email</legend>
                <input
                  type="email"
                  id="email"
                  className="w-full p-2 rounded-lg focus:outline-none"
                  placeholder="Input here"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </fieldset>
            </div>
            <div className="btn flex justify-center items-center">
              <button
                type="submit"
                className="rounded-full m-2 text-white w-3/6 px-4 py-4 shadow-md hover:bg-white transition duration-200 ease-in"
                style={{ background: 'linear-gradient(to bottom right, #27d0b6, #24c8b5, #1bb1af, #18a8ad)' }}
              >
                Submit
              </button>
            </div>
          </form>
        )}

        {step === 3 && (
          <form onSubmit={handlePasswordSubmit}>
            <div className="mb-4 flex justify-center space-x-2">
              {otp.map((data, index) => (
                <input
                  key={index}
                  type="text"
                  name="otp"
                  maxLength="1"
                  className="w-12 h-12 border border-gray-300 text-center rounded-md text-2xl"
                  value={data}
                  onChange={(e) => handleOtpChange(e.target, index)}
                  onFocus={(e) => e.target.select()}
                />
              ))}
            </div>
            <div className="mb-4">
              <fieldset className="border border-gray-300 rounded-lg">
                <legend className="text-lg font-semibold ml-4">Set New Password</legend>
                <input
                  type="password"
                  id="password"
                  className="w-full p-2 rounded-lg focus:outline-none"
                  placeholder="Input password here"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </fieldset>
              {errors.password && (
                <p className="text-red-500 text-xs italic">{errors.password}</p>
              )}
            </div>
            <div className="mb-4">
              <fieldset className="border border-gray-300 rounded-lg">
                <legend className="text-lg font-semibold ml-4">Confirm New Password</legend>
                <input
                  type="password"
                  id="confirmPassword"
                  className={`w-full p-2 focus:outline-none rounded-2xl ${passwordMatch ? 'border-gray-300' : 'border-red-500'}`}
                  placeholder="Confirm password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                />
              </fieldset>
              {!passwordMatch && <p className="text-red-500 mt-2">Passwords do not match</p>}
            </div>
            <div className="btn flex justify-center items-center">
              <button
                type="submit"
                className="rounded-full m-2 text-white w-3/6 px-4 py-4 shadow-md hover:bg-white transition duration-200 ease-in"
                style={{ background: 'linear-gradient(to bottom right, #27d0b6, #24c8b5, #1bb1af, #18a8ad)' }}
              >
                Submit Verification
              </button>
            </div>
          </form>
        )}

   
        {step === 4 && (
          <div>
            <div className="btn flex justify-center items-center">
              <button
                type="button"
                className="rounded-full m-2 text-white w-3/6 px-4 py-4 shadow-md hover:bg-white transition duration-200 ease-in"
                style={{ background: 'linear-gradient(to bottom right, #27d0b6, #24c8b5, #1bb1af, #18a8ad)' }}
                onClick={handleLogin}
              >
                Login
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
