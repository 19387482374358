import React, { useEffect, useState } from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import { ImCross } from "react-icons/im";

function CreateAccount() {
  const [email, setEmail] = useState('');
  const [descrition, setDescription] = useState('');
  const [partnerCode, setPartnerCode] = useState('');
  const [amount, setAmount] = useState('');
  const [selectedFile, setSelectedFile] = useState(null); // State for the file input
  const [showPopup, setShowPopup] = useState(false);
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable
  const navigate = useNavigate();

  useEffect(() => {
    const sessionPartnerCode = localStorage.getItem('partnerCode');
    const sessionEmail = localStorage.getItem('userName');
    const sessionAmount = localStorage.getItem('amount');

    if (sessionPartnerCode) setPartnerCode(sessionPartnerCode);
    if (sessionEmail) setEmail(sessionEmail);
    if (sessionAmount) setAmount(sessionAmount);
  }, []);

  const handleSubmit = async () => {
    const token = localStorage.getItem('token');
    const sessionPartnerCode = localStorage.getItem('partnerCode');
  
    if (!token || !sessionPartnerCode) {
      alert('Missing token or partner code');
      return;
    }
  
    // Construct FormData for file upload and other data
    const formData = new FormData();
    formData.append('iccid', 'Top-request');
    formData.append('descrition', descrition);
    formData.append('partnercode', sessionPartnerCode);
    formData.append('issuedepartment', '4');
    formData.append('amount', amount);
    formData.append('FileName', 'Proof');
    formData.append('status', 0);
  
    if (selectedFile) {
      formData.append('File', selectedFile); // Append file to FormData
    }
  
    // Create an array to store the form data for displaying in alert
    const formDataEntries = [];
    for (let pair of formData.entries()) {
      formDataEntries.push(`${pair[0]}: ${pair[1]}`);
    }
  
    // Display the formData content in an alert
    alert(`FormData being sent:\n${formDataEntries.join('\n')}`);
  
    try {
      const response = await fetch(`${apiUrl}ESIM/CreateTicket`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData, // Use FormData in the body
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
          alert('Thank you for your interest in topping up your Airhub account.');
        }, 3000);
      } else {
        alert(`Error: ${data.message || 'Something went wrong'}`);
      }
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };
  

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]); // Handle file input
  };

  const handleCross = () => {
    navigate('/Topups');
  };

  return (
    <div className="flex h-screen">
      <div style={{ backgroundColor: '#ffffff' }} className="main-content flex-1 bg-teal-100 overflow-auto">
        <div className="p-4">
          <div className="flex justify-between items-center">
            <h1 className="text-[2rem] items-center font-bold">Contact Account Manager</h1>
            <div className="arrow w-16 h-16 flex justify-center items-center rounded-full bg-[#ffffff] shadow-md">
              <Link to="/Home" className="text-2xl">
                <IoCloseSharp size={40} />
              </Link>
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="partnerCode" className="text-teal-900 block mb-1">Partner Code</label>
            <input type="text" id="partnerCode" name="partnerCode" value={partnerCode} readOnly className="w-full p-3 border-2 border-#91b1b5 rounded-md" style={{ backgroundColor: '#ffffff', borderColor: '#91b1b5' }} />
          </div>

          <div className="mb-4">
            <label htmlFor="email" className="text-teal-900 block mb-1">Email</label>
            <input type="email" id="email" name="email" value={email} readOnly className="w-full p-3 border-2 border-#91b1b5 rounded-md" style={{ backgroundColor: '#ffffff', borderColor: '#91b1b5' }} />
          </div>

          <div className="mb-4">
            <label htmlFor="amount" className="text-teal-900 block mb-1">Amount Request</label>
            <div className="relative">
              <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">$</span>
              <input type="number" id="amount" name="amount" value={amount} readOnly className="w-full pl-7 p-3 border-2 border-#91b1b5 rounded-md" style={{ backgroundColor: '#ffffff', borderColor: '#91b1b5' }} />
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-6">
  <div className="w-full px-3">
    <label htmlFor="file-upload" className="text-teal-900 block mb-1">Upload File</label>
    <input
      type="file"
      id="file-upload"
      className="w-full pl-7 p-3 border-2 border-#91b1b5 rounded-md"
      style={{ backgroundColor: '#ffffff', borderColor: '#91b1b5' }}
      accept=".pdf" // Restricts file upload to PDF only
      onChange={handleFileChange}
    />
  </div>
</div>


          <div className="mb-4">
            <label htmlFor="description" className="text-teal-900 block mb-1">Description</label>
            <textarea id="description" name="description" rows="4" cols="50" maxLength="50" placeholder="Enter text here..." className="w-full p-3 border-2 border-#91b1b5 rounded-md" style={{ backgroundColor: '#ffffff', borderColor: '#91b1b5' }} onChange={(e) => setDescription(e.target.value)}></textarea>
          </div>

          <button onClick={handleSubmit} style={{ float: 'right' }} type="button" className="button bg-orange-500 text-white px-8 py-4 font-semibold rounded-full">Submit</button>
        </div>
      </div>

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 shadow-md flex flex-col items-center rounded-3xl">
            <img className="rounded-11xl h-56 mb-4" alt="" src="/tick.svg" />
            <ImCross size={30} onClick={handleCross} className="cursor-pointer absolute top-2 right-2" />
            <p className="w-[555px] font-bold text-lg text-center">
              Thank you for your top-up request. Our team will send you payment details shortly. Once payment is confirmed, your account will be topped up.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default CreateAccount;
