import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import HamburgerButton from "./HamburgerMenuButton/HamburgerButton";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const [open] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(false);
  const location = useLocation();
  const [walletData, setWalletData] = useState(null);
  const [sessionToken, setSessionToken] = useState(null);
  const [partnerCode, setPartnerCode] = useState(null);
  const [userName, setUserName] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false); // State to track admin role
  const [isPartner, setIsPartner] = useState(false); // State to track partner role
  const [isPayaspergo, setIsPayaspergo] = useState(false); // State to track partner role
  const [isfinance, setIsfinance] = useState(false); // State to track partner role

  const [userRole, setUserRole] = useState(null); // State for user role
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable
  const navigate = useNavigate();
 
  useEffect(() => {
    const fetchSessionData = () => {
      const storedSessionToken = localStorage.getItem("token");
      const storedPartnerCode = localStorage.getItem("partnerCode");
      const storedUserName = localStorage.getItem("Name");
      const storedRole = localStorage.getItem("role"); // Fetch user role

      if (
        storedSessionToken &&
        storedPartnerCode &&
        storedUserName &&
        storedRole
      ) {
        setSessionToken(storedSessionToken);
        setPartnerCode(storedPartnerCode);
        setUserName(storedUserName);
        setIsAdmin(storedRole === "admin"); // Check if user is admin
        setIsPartner(storedRole === "Partner"); // Check if user is partner
        setIsPayaspergo(storedRole === "Payaspergo"); // Check if user is partner
        setIsfinance(storedRole === "finance"); // Check if user is partner
        } else {
        navigate("/");
      }
    };
    fetchSessionData();
  }, [navigate]);

  useEffect(() => {
    const fetchWalletData = async () => {
      const sessionToken = localStorage.getItem("token");
      const partnerCode = localStorage.getItem("partnerCode");

      try {
        if (!sessionToken || !partnerCode) {
          console.error(
            "Session token or partner code missing",
            sessionToken,
            partnerCode
          );
          return;
        }

        const response = await axios.get(
          `${apiUrl}ESIMGOPartner/get_wallet_invidual?partnercode=${partnerCode}`,
          {
            headers: {
              Authorization: `Bearer ${sessionToken}`,
            },
          }
        );
        setWalletData(response.data.getindividualwallet[0]);
      } catch (error) {
        console.error("Error fetching wallet data:", error);
      }
    };

    fetchWalletData();
  }, [sessionToken, partnerCode]);

  const firstMenuItems = [
    // { title: 'Dashboard', path: '/Home', icon: '/icons.svg' },
    // { title: 'eSIMs Store', path: '/BuyDataBundles', icon: '/icons1.svg' },
    // { title: 'Transaction History', path: '/GetplanInformation', icon: '/icons6.svg' },
    // { title: 'Wallet Top-Up', path: '/TopUps', icon: '/icons3.svg' },
    // { title: 'News & Updates', path: '/News', icon: '/icons4.svg' },
  ];

  if (isAdmin) {
    firstMenuItems.push(
      { title: "Dashboard", path: "/SuperadminDashboard", icon: "/icons.svg" },
      { title: "Manage Partner", path: "/Superadmin", icon: "/icons6.svg" }
    );
  }
  if (isfinance) {
    firstMenuItems.push(
      { title: "Dashboard", path: "/SuperadminDashboard", icon: "/icons.svg" },
      { title: "Manage Partner", path: "/Superadmin", icon: "/icons6.svg" },
      // {title: "Create Parnter",path: "/SuperadminPartner",icon: "/icons2.svg",},
      // {title: "SuperadminUpdate List ",path: "/SuperadminVendor",icon: "/icons5.svg",},
      // { title: "Partner Update ", path: "/Superadminpay", icon: "/icons1.svg" },
      // { title: "API Box", path: "/ApiBox", icon: "/icons3.svg" },
      // { title: "Tickets", path: "/SuperadminTicket", icon: "/icons7.svg" },
      // { title: "Vendor Lists", path: "/SuperadminUpdate", icon: "/icons4.svg" },
    );
  }

  if (isPartner) {
    firstMenuItems.push(
      { title: "Dashboard", path: "/Home", icon: "/icons.svg" },
      { title: "eSIMs Store", path: "/BuyDataBundles", icon: "/icons1.svg" },
      // { title: "Regional eSIMs Store", path: "/Buydatad", icon: "/icons2.svg" },
      {title: "Transaction History",path: "/GetplanInformation",icon: "/icons6.svg"},
      { title: "Wallet Top-Up", path: "/AddmoreBalance", icon: "/icons3.svg" },
      { title: "News & Updates", path: "/News", icon: "/icons4.svg" }
    );
  }
  if (isPayaspergo) {
    firstMenuItems.push(
      { title: "Dashboard", path: "/Home", icon: "/icons.svg" },
      { title: "eSIMs Store", path: "/BuyDataBundles", icon: "/icons1.svg" },
      // { title: "Regional eSIMs Store", path: "/Buydatad", icon: "/icons2.svg" },
      { title: "News & Updates", path: "/News", icon: "/icons4.svg" }
    );
  }
  
  const secondMenuItems = [
    // { title: 'Purchase History', path: '/SimManagement', icon: '/icons8.svg' },
    // Add more second menu items as needed
  ];
  if (isAdmin) {
    secondMenuItems.push(
      {title: "Purchase Details",path: "/SuperadminWallet",icon: "/icons8.svg",},
      {title: "Create Parnter",path: "/SuperadminPartner",icon: "/icons2.svg",},
      // {title: "SuperadminUpdate List ",path: "/SuperadminVendor",icon: "/icons5.svg",},
      // { title: "Partner Update ", path: "/Superadminpay", icon: "/icons1.svg" },
      { title: "API Box", path: "/ApiBox", icon: "/icons3.svg" },
      { title: "Tickets", path: "/SuperadminTicket", icon: "/icons7.svg" },
      // { title: "Vendor Lists", path: "/SuperadminUpdate", icon: "/icons4.svg" },

      
    );
  }
  if (isPartner) {
    secondMenuItems.push({title: "Purchase History",path: "/SimMangement",icon: "/icons8.svg",});
  }
  if (isPayaspergo) {
    secondMenuItems.push({title: "Purchase History",path: "/SimMangement",icon: "/icons8.svg",    });
  }
  if (isfinance) {
    secondMenuItems.push( 
       {title: "Purchase Details",path: "/SuperadminWallet",icon: "/icons8.svg",},
       { title: "Tickets", path: "/SuperadminTicket", icon: "/icons7.svg" },

      );
  

  }



  const thirdMenuItems = [
    // { title: 'API Box', path: '/ApiBox', icon: '/icons7.svg' },
    // { title: 'Help & Support', path: '/HelpSupport', icon: '/icons8.svg' },
    // { title: 'Create Ticket', path: '/CreateTicket', icon: '/icons2.svg' },
    // // Add more third menu items as needed
  ];

  if (isPartner) {
    thirdMenuItems.push(
      { title: "API Box", path: "/ApiBox", icon: "/icons7.svg" },
      { title: "Help & Support", path: "/HelpSupport", icon: "/iconhelp.svg" },
      { title: "Create Ticket", path: "/CreateTicket", icon: "/icons2.svg" }
      // Add more third menu items as needed
    );
  }
  if (isPayaspergo) {
    thirdMenuItems.push(
      { title: "API Box", path: "/ApiBox", icon: "/icons7.svg" },
      { title: "Help & Support", path: "/HelpSupport", icon: "/iconhelp.svg" },
      { title: "Create Ticket", path: "/CreateTicket", icon: "/icons2.svg" }
      // Add more third menu items as needed
    );
  }

  const renderMenuItem = (menu, index) => {
    const isSelected = location.pathname === menu.path;
    const iconStyle = isSelected
      ? { filter: "invert(100%) brightness(100%)" }
      : {};
      return (
        <Link to={menu.path} key={index}>
          <li
            className={`flex items-center gap-x-6 p-3 pl-8 text-base relative font-large rounded-lg cursor-pointer dark:text-white hover:bg-[#dbedec] dark:hover:bg-gray-700 ${
              isSelected && 'bg-[#117575] text-white dark:bg-gray-700'
            }`}
          >
            <img className="w-7 h-7 relative overflow-hidden shrink-0" alt="" src={menu.icon} style={iconStyle} />
            <span className={`${!open && 'hidden'} origin-left duration-300 hover:block`}>{menu.title}</span>
          </li>
        </Link>
      );
    };

    useEffect(() => {
      // Fetch user role from localStorage
      const role = localStorage.getItem("role");
      setUserRole(role);
    }, []);
  
    return (
      <>
        <div style={{ backgroundColor: '#ffffff', width: '18rem', minHeight: '100vh', borderRadius: '5%', }} className={`${open ? 'w-60' : 'w-fit'} hidden sm:block relative duration-300`}>
  
          <div style={{ backgroundColor: '#117575', borderRadius: '1rem', height: '120px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="text-white flex flex-col justify-center">
            <div className="text-white text-lg font-bold text-center" style={{ fontSize: '1.7rem', paddingTop: '25px' }}>{userName}</div>
            <div style={{ backgroundColor: '#4cc8c1', padding: '10px', display: 'inline-block', width: 'fit-content', borderRadius: '0.7rem', fontSize: '0.9rem' }} className="text-white inline-block text-center mt-2 m-4 shadow-box">
           {userRole =='Partner' &&(
            <>
              {walletData ? (
                <p className="text-2xl font-bold ">{`$${walletData.balance}`}</p>
              ) : (
                <p className="text-lg text-gray-600">Loading...</p>
              )}
              </>
            )}
            </div>
          </div>
  
          <ul className='pt-2 overflow-y-auto' style={{ maxHeight: 'calc(80vh - 172px)' }}>
            {firstMenuItems.map(renderMenuItem)}
          </ul>
  
          <hr className="my-4 border-gray-400" />
  
          <ul className='pt-2 overflow-y-auto' style={{ maxHeight: 'calc(100vh - 372px)' }}>
            {secondMenuItems.map(renderMenuItem)}
          </ul>
  
          <hr className="my-4 border-gray-400" />
  
          <ul className=' overflow-y-auto' style={{ maxHeight: 'calc(100vh - 272px)' }}>
            {thirdMenuItems.map(renderMenuItem)}
          </ul>
        </div>
  
        {/* Mobile Menu */}
        <div className="pt-3">
          <HamburgerButton
            setMobileMenu={setMobileMenu}
            mobileMenu={mobileMenu}
          />
        </div>
  
        <div className="sm:hidden">
          <div className={`${
            mobileMenu ? 'flex' : 'hidden'
          } absolute z-50 flex-col items-center self-end py-8 mt-16 space-y-6 font-bold sm:w-auto left-6 right-6 dark:text-white  bg-gray-50 dark:bg-slate-800 drop-shadow md rounded-xl`}>
            {firstMenuItems.map((menu, index) => (
              <Link
                to={menu.path}
                key={index}
                onClick={() => setMobileMenu(false)}
              >
                <span
                  className={` ${
                    location.pathname === menu.path &&
                    'bg-gray-200 dark:bg-gray-700'
                  } p-2 rounded-xl hover:bg-gray-200 dark:hover:bg-gray-700`}
                >
                  {menu.title}
                </span>
              </Link>
            ))}
            <hr className="my-4 border-gray-400" />
            {secondMenuItems.map((menu, index) => (
              <Link
                to={menu.path}
                key={index}
                onClick={() => setMobileMenu(false)}
              >
                <span
                  className={` ${
                    location.pathname === menu.path &&
                    'bg-gray-200 dark:bg-gray-700'
                  } p-2 rounded-xl hover:bg-gray-200 dark:hover:bg-gray-700`}
                >
                  {menu.title}
                </span>
              </Link>
            ))}
            <hr className="my-4 border-gray-400" />
            {thirdMenuItems.map((menu, index) => (
              <Link
                to={menu.path}
                key={index}
                onClick={() => setMobileMenu(false)}
              >
                <span
                  className={` ${
                    location.pathname === menu.path &&
                    'bg-gray-200 dark:bg-gray-700'
                  } p-2 rounded-xl hover:bg-gray-200 dark:hover:bg-gray-700`}
                >
                  {menu.title}
                </span>
              </Link>
            ))}
          </div>
        </div>
      </>
    );
  };
  

export default Sidebar;