import React, { useState, useEffect } from "react";
import { LuWallet } from "react-icons/lu";
import { FaChevronLeft, FaChevronRight, FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import coin from '../assets/images/coin.svg';

function GetplanInformation() {
  const [walletData, setWalletData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [sessionToken, setSessionToken] = useState(null);
  const [partnerCode, setPartnerCode] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable

  const itemsPerPage = 5;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSessionData = () => {
      const storedSessionToken = localStorage.getItem("token");
      const storedPartnerCode = localStorage.getItem("partnerCode");

      if (storedSessionToken && storedPartnerCode) {
        setSessionToken(storedSessionToken);
        setPartnerCode(storedPartnerCode);
      } else {
        console.error("Session data not found");
      }
    };

    fetchSessionData();
  }, []);

  useEffect(() => {
    const fetchWallet = async () => {
      const sessionToken = localStorage.getItem("token");
      const partnerCode = localStorage.getItem("partnerCode");
      try {
        if (!sessionToken || !partnerCode) {
          console.error("Session token or partner code missing");
          return;
        }

        const response = await axios.get(
          `${apiUrl}ESIMGOPartner/get_wallet_invidual?partnercode=${partnerCode}`,
          {
            headers: {
              Authorization: `Bearer ${sessionToken}`,
            },
          }
        );
        setWallet(response.data.getindividualwallet[0]);
      } catch (error) {
        console.error("Error fetching wallet data:", error);
      }
    };

    fetchWallet();
  }, [sessionToken, partnerCode]);

  useEffect(() => {
    const fetchWalletData = async () => {
      try {
        const partnerCode = localStorage.getItem("partnerCode");
        const token = localStorage.getItem("token");

        if (!partnerCode) {
          throw new Error("Partner code is missing from session");
        }

        if (!token) {
          throw new Error("Token is missing from session");
        }

        const response = await axios.post(
          `${apiUrl}ESIMGOPartner/GetWallet`,
          {
            partnerCode: partnerCode,
            flag: 1,
            countryCode: "",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.isSuccess) {
          setWalletData(response.data.getwallet);
        } else {
          setError("Failed to fetch wallet data");
        }
      } catch (error) {
        setError("An error occurred while fetching wallet data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchWalletData();
  }, []);

  const handleNavigateToDetails = () => {
    navigate("/AddmoreBalance");
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(walletData.length / itemsPerPage))
    );
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = walletData.slice(startIndex, startIndex + itemsPerPage);
  const totalPages = Math.ceil(walletData.length / itemsPerPage);

  const Pagination = ({ totalPages, currentPage, onPageClick }) => {
    const maxVisiblePages = 5; // Maximum number of visible page numbers
    let pages = [];

    if (totalPages <= maxVisiblePages) {
      pages = Array.from({ length: totalPages }, (_, index) => index + 1);
    } else {
      const leftEllipsis = currentPage > 4;
      const rightEllipsis = currentPage < totalPages - 3;

      if (leftEllipsis) {
        pages.push(1);
        pages.push(',');
      }

      for (
        let i = Math.max(2, currentPage - 2);
        i <= Math.min(totalPages - 1, currentPage + 2);
        i++
      ) {
        pages.push(i);
      }

      if (rightEllipsis) {
        pages.push(',');
        pages.push(totalPages);
      }
    }

    return (
      <div className="flex justify-between mt-4">
        <button
          className="pagination-button"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          <FaChevronLeft />
        </button>
        <div className="flex">
          {pages.map((page, index) => (
           <button
           key={index}
           className={`pagination-item ${
             currentPage === page ? "active" : ""
           }`}
           onClick={() => page !== "..." && onPageClick(page)}
           style={{
             fontWeight: currentPage === page ? "bold" : "normal",
             backgroundColor: currentPage === page ? "#117575" : "transparent",
             color: currentPage === page ? "white" : "black",
             padding:'16px',
             borderRadius:'0.9rem'
           }}
         >
           {page}
         </button>

          ))}
        </div>
        <button
          className="pagination-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          <FaChevronRight />
        </button>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex flex-col lg:flex-row bg-white rounded-2xl">
        <div
          className={`sidebar bg-[#117575] flex justify-center items-center rounded-2xl lg:w-96 w-full lg:block ${isSidebarOpen ? "block" : "hidden"}`}
        >
          <div className="content-sidebar p-4 lg:p-0 flex flex-col justify-center items-center h-full">
            <p className="text-white text-center text-5xl mb-4">Available Balance</p>
            <span className="text-[3rem] font-bold text-white">Loading...</span>
            <p className="text-white text-center text-5xl mt-4">Wallet Threshold</p>
            <span className="text-5xl font-bold text-white">$2000</span>
          </div>
        </div>
        <div className="table-content p-4 h-screen w-full flex items-center justify-center">
          <div>Loading...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col lg:flex-row bg-white rounded-2xl">
      <button
        className="sidebar-toggle lg:hidden p-4"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <FaBars size={30} />
      </button>

      <div
        className={`sidebar bg-[#117575] flex justify-center items-center rounded-2xl lg:w-96 w-full lg:block ${isSidebarOpen ? "block" : "hidden"}`}
      >
        <div className="content-sidebar p-4 lg:p-0 flex flex-col justify-center items-center h-full">
          <p className="text-white text-center font-bold text-5xl mb-4">Available Balance</p>
          <div className="flex items-center space-x-2 bg-[#092e27] p-2 rounded-2xl">
            <span className="text-5xl font-bold text-white">
              {wallet ? `$${wallet.balance}` : "Loading..."}
            </span>
            <img src={coin} alt="coin" className="w-8 h-8"/>
          </div>

          <p className="text-white text-center font-bold text-5xl mt-4">Wallet Threshold</p>
          <div className="flex items-center space-x-2 bg-[#092e27] p-2 rounded-2xl">
            <span className="text-5xl font-bold text-white px-2">$2000</span>
            <img src={coin} alt="coin" className="w-8 h-8"/>
          </div>
        </div>
      </div>

      <div className="table-content p-4 h-screen w-full">
        <div className="flex justify-between items-center mb-8">
          <h1 className="flex items-center text-5xl font-bold">
            <LuWallet size={40} className="mr-4" />
            Wallet
          </h1>
      
        </div>
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th className="border-b-2 p-4">Date</th>
              <th className="border-b-2 p-4">Amount</th>
              <th className="border-b-2 p-4">Type</th>
              <th className="border-b-2 p-4">Description</th>
              <th className="border-b-2 p-4">Balance</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.length === 0 ? (
              <tr>
                <td colSpan="5" className="py-4 text-center">No Transactions Happened Till Now</td>
              </tr>
            ) : (
              currentItems.map((transaction, index) => (
                <tr key={index} className="border-b">
                  <td className="py-2 text-center">{transaction.date}</td>
                  <td className="py-2 text-center">  ${parseFloat(transaction.amount.replace('$', '')).toFixed(2)}                  </td>
                  <td className="py-2 text-center">{transaction.type}</td>
                  <td className="py-2 text-center">{transaction.description}</td>
                  <td className="py-2 text-center">${transaction.balance.replace('$', '')}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageClick={handlePageClick}

  />
      </div>
      <div className="absolute bottom-0 right-0 mb-10 mr-10">
        <button
          className="signupbtn bg-orange-500 text-white text-xl font-bold px-10 py-4 rounded-3xl"
          onClick={handleNavigateToDetails}
        >
          Recharge
        </button>
      </div>
    </div>
  );
}

export default GetplanInformation;
