import React from 'react';

const Background = ({ children }) => {
    return (
        <div
            style={{ backgroundColor: '#daefef', minHeight: '100vh', width: '100%' }}
            className="dark:bg-gray-800"
        >
            {children}
        </div>
    );
};

export default Background;
