import React, { useEffect, useState } from "react";
import axios from "axios";

const SuperadminTicket = () => {
  const [tickets, setTickets] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [filter, setFilter] = useState("");
  const [showfile, setShowFile] = useState(false); // State to track file details visibility
  const apiUrl = process.env.REACT_APP_BASE_URL;
  const [fileData, setFileData] = useState(null);

  const handleFileClick = (ticket_id) => {
    setShowFile(true);
    fetchFileData(ticket_id); // Fetch file data when the file name is clicked
  };

  const fetchFileData = async (ticket_id) => {
    try {
      const token = localStorage.getItem('token'); // Retrieve token from local storage
      const response = await axios.get(`${apiUrl}ESIMGOAdmin/GetTicket?ticketid=${ticket_id}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in the request headers
        },
        responseType: 'blob', // Expect a binary file in the response
      });
  
      // Create a new Blob object for the file
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'invoice.pdf'; // The name of the file to be downloaded
      link.click(); // Trigger the download
  
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };
  
    

  useEffect(() => {
    const partnerCode = localStorage.getItem("partnerC");
    const token = localStorage.getItem("token");

    const fetchTickets = async () => {
      try {
        const response = await axios.get(`${apiUrl}ESIMGOAdmin/Get_AllTicketsData`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.isSuccess) {
          const sortedTickets = response.data.ticketList.sort((a, b) => {
            return b.ticket_id - a.ticket_id;
          });
          setTickets(sortedTickets);
        }
      } catch (error) {
        console.error("Error fetching ticket data:", error);
      }
    };

    fetchTickets();
  }, [apiUrl]);

  const handleOpenClick = (ticketId) => {
    setSelectedTicketId(ticketId);
    setShowPopup(true);
  };

  const handleConfirmClose = async () => {
    const token = localStorage.getItem("token");
    // Check if the token exists
    if (!token) {
      console.error("No token found, redirecting to login.");
      return; // Handle this by redirecting the user to login or showing an error
    }
    try {
      const response = await axios.post(
        `${apiUrl}ESIMGoAdmin/update_partnerticket_status`,
        {
          id: selectedTicketId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.isSuccess) {
        alert("Ticket has been successfully closed!");
        setShowPopup(false);
      }
    } catch (error) {
      console.error("Error updating ticket status:", error);
    }
  };

  const handleCancelClose = () => {
    setShowPopup(false);
    setSelectedTicketId(null);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  // Filter tickets based on the selected department
  const filteredTickets = tickets.filter((ticket) =>
    filter === "" ? true : ticket.issuedepartment === filter
  );

  return (
    <div className="mt-4 bg-white shadow p-4 justify-between items-center rounded-3xl">
      <h1 className="text-5xl font-bold">Ticket Details</h1>

      {/* Dropdown to filter by department */}
      <div className="mt-4">
        <label className="mr-2 font-bold">Filter by Department:</label>
        <select className="border rounded p-2" value={filter} onChange={handleFilterChange}>
          <option value="">All Departments</option>
          <option value="IT Team">IT Team</option>
          <option value="Network Team">Network Team</option>
          <option value="Vendor Problem">Vendor Problem</option>
          <option value="Top Up Request">Top Up Request</option>
        </select>
      </div>

      <div className="mt-4 p-4 flex justify-between items-center">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 text-center border-b">Ticket ID</th>
              <th className="py-2 px-4 text-center border-b">Partner Code</th>
              <th className="w-2 text-center border-b">Description</th>
              <th className="py-2 px-4 text-center border-b">Issue Department</th>
              <th className="py-2 px-4 text-center border-b">Amount</th>
              <th className="w-2 text-center border-b">File Name</th>
              <th className="w-2 text-center border-b">Create Date</th>
              <th className="py-2 px-4 text-center border-b">Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredTickets.length > 0 ? (
              filteredTickets.map((ticket, index) => (
                <tr key={index}>
                  <td className="py-2 px-4 text-center border-b">{ticket.ticket_id || "NA"}</td>
                  <td className="py-2 px-4 text-center border-b">{ticket.partnercode || "NA"}</td>
                  <td className="py-2 px-4 text-center border-b">{ticket.descrition || "NA"}</td>
                  <td className="py-2 px-4 text-center border-b">{ticket.issuedepartment || "NA"}</td>
                  <td className="py-2 px-4 text-center border-b">${ticket.amount || "NA"}</td>
                  <td
                    className="py-2 px-4 text-center border-b cursor-pointer text-blue-600 underline"
                    onClick={() => handleFileClick(ticket.ticket_id)}
                  >
                    {ticket.fileName || "NA"}
                  </td>
                  <td className="py-2 px-4 text-center border-b">{ticket.created_date || "NA"}</td>

                  <td className="py-2 px-4 text-center border-b">
                    <button
                      className="rounded-full px-6 py-2 text-white shadow-md hover:bg-white transition duration-200 ease-in"
                      style={{
                        background:
                          ticket.status === "Close"
                            ? "red"
                            : "linear-gradient(to bottom right, #27d0b6, #24c8b5, #1bb1af, #18a8ad)",
                        cursor: ticket.status === "Close" ? "not-allowed" : "pointer",
                      }}
                      disabled={ticket.status === "Close"}
                      onClick={() => handleOpenClick(ticket.ticket_id)}
                    >
                      {ticket.status}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td className="px-4 py-2 text-center whitespace-nowrap" colSpan={6}>
                  No tickets found for the selected department.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {showPopup && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded shadow-lg">
            <h1 className="text-3xl mb-4">Please Enter Reason of Closing Ticket</h1>
            <input type="text" className="border rounded p-2 w-full mb-4" placeholder="Enter reason" />
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded mr-2"
                onClick={handleCancelClose}
              >
                Cancel
              </button>
              <button className="bg-green-500 text-white px-4 py-2 rounded" onClick={handleConfirmClose}>
                Confirm Close
              </button>
            </div>
          </div>
        </div>
      )}

      {showfile && fileData && (
        <div>
          <h2>File Name: {fileData.fileName}</h2>
          <p>File Size: {fileData.contentLength} bytes</p>
          <p>File Type: {fileData.fileType}</p>
        </div>
      )}
    </div>
  );
};

export default SuperadminTicket;
