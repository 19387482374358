import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function SuperadminUpdate() {
  const [partnerCode, setPartnerCode] = useState('');
  const [email, setEmail] = useState(''); // State for email
  const [balance, setBalance] = useState(0); // State for balance
  const [addBalance, setAddBalance] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [discount, setDiscount] = useState('');
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
  const [ispartnerModalOpen, setIspartnerModalOpen] = useState(false);
  const [userRole, setUserRole] = useState(null); // State for user role

  const [partner, setPartner] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedType, setSelectedType] = useState("");

  const apiUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const storedPartnerCode = localStorage.getItem('selectedPartnerCode');
    const storedEmail = localStorage.getItem('email');
    const storedBalance = localStorage.getItem('balance');
    const storeddiscount = localStorage.getItem('discount');

    if (storedPartnerCode) {
      setPartnerCode(storedPartnerCode);
    }
    if (storedEmail) {
      setEmail(storedEmail);
    }
    if (storedBalance) {
      setBalance(storedBalance); // Assuming balance is a number, you may need to convert it
    }
    if (storeddiscount) {
      setDiscount(storeddiscount);
    }
  }, []);

  const handleSubmit = async () => {
    try {
      const token = await localStorage.getItem('token');
      const storedPartnerCode = localStorage.getItem('selectedPartnerCode');
      const partnerCode = localStorage.getItem('partnerCode');
      const response = await fetch(`${apiUrl}ESIMGOAdmin/updatewallet_balance`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          partnercode: storedPartnerCode,
          addbalance: addBalance,
          updatedby: partnerCode,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          `Failed to update wallet balance: ${errorData.message || response.statusText}`
        );
      }

      alert('Wallet amount updated successfully');
      setIsModalOpen(false);
      setAddBalance(0);
    } catch (error) {
      console.error('Error updating wallet balance:', error);
    }
  };

  const handleEyeClick = (code) => {
    setIsModalOpen(true);
    setPartnerCode(code);
  };
  // 
  const handlePartnerClick = (code) => {
    setIspartnerModalOpen(true);
    setPartnerCode(code);
  };
  const handleDiscountClick = (code) => {
    setIsDiscountModalOpen(true);
    setPartnerCode(code);
  };

  const handleDiscountSubmit = async () => {
    try {
      const token = await localStorage.getItem('token');
      const storedPartnerCode = localStorage.getItem('selectedPartnerCode');

      const response = await fetch(`${apiUrl}ESIMGOAdmin/UpdatePartner_discount`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          discount: discount,
          partnercode: storedPartnerCode,
          updated_by: partnerCode,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update discount');
      }
      alert('Discount has been changed successfully');
      setIsDiscountModalOpen(false);
      setDiscount('');
    } catch (error) {
      console.error('Error updating discount:', error);
    }
  };

 
  const handleTypeChange = async () => {
    const storedPartnerCode = localStorage.getItem('selectedPartnerCode');

    if (!storedPartnerCode || !selectedType) return;
  console.log(storedPartnerCode)
    const payload = {
      partnercode: storedPartnerCode,
      type: selectedType,
    };
  
    console.log("Payload being sent:", payload);
  
    try {
      const token = await localStorage.getItem('token');
      const response = await fetch(`${apiUrl}ESIMGOAdmin/UpdatePartner_type`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error("Failed to update partner type");
      }
  
      // Update local state or refetch data if necessary
      alert("Partner type updated successfully");
      ispartnerModalOpen(false);
    } catch (error) {
      console.error("Error updating partner type:", error);
    }
  };
  const handlenavigatevendor =()=>{
    navigate('/SuperadminUpdate')
  }
  useEffect(() => {
    // Fetch user role from localStorage
    const role = localStorage.getItem("role");
    setUserRole(role);
  }, []);
  useEffect(() => {
    const fetchPartnerDetails = async () => {
      try {
        const token = localStorage.getItem('token'); // Replace with your actual token key

        const response = await axios.get(`${apiUrl}ESIMGOAdmin/partner_details?flag=1`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.isSuccess) {
          const partnerCode = localStorage.getItem('selectedPartnerCode');
          const foundPartner = response.data.partnerlist.find(partner => partner.partnercode === partnerCode);
          setPartner(foundPartner);
        } else {
          setError('Failed to fetch partner details');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPartnerDetails();
  }, []);
  const downloadReport = async () => {
    setLoading(true);
    setError(null);

    const partnerCode = localStorage.getItem('selectedPartnerCode');
    const url = `${apiUrl}ESIMGOAdmin/Get_purchasereport_sourcewise?flag=3&partnercode=${partnerCode}`;

    try {
        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}` // Assuming the token is stored in local storage
            }
        });

        if (response.data.isSuccess) {
            // Convert the response data to CSV format
            const csvData = response.data.partnersale.map((sale) => ({
                PartnerName: sale.partnername,
                CompanyName: sale.companyname,
                PurchaseDate: sale.purchasedate,
                PlanName: sale.planname,
                SaleType: sale.saletype,
                PartnerCode: sale.partner_code,
                OrderId: sale.order_id,
                PurchaseAmount: sale.purchaseamount,
                ActivationCode: sale.activationCode,
                SIMPin: sale.simpin,
                APN: sale.apn,
                ICCID: sale.iccid,
                SMDP: sale.smdp
            }));

            // Convert JSON to CSV
            const csvContent = [
                Object.keys(csvData[0]).join(','), // header row
                ...csvData.map(row => Object.values(row).join(',')) // data rows
            ].join('\n');

            // Create a Blob and a link to download the file
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', 'PurchaseReport.csv');
            document.body.appendChild(link)
; // Required for FF

            link.click(); // Simulate click to download
            document.body.removeChild(link)
; // Clean up
        } else {
            throw new Error(response.data.message || 'Failed to download report');
        }
    } catch (err) {
        setError(err.message);
    } finally {
        setLoading(false);
    }
};


  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  return (
    <>
     <div style={{ backgroundColor: '#ffffff' }} className="main-content flex-1 bg-teal-100 overflow-auto p-4 rounded-md">
        <h1 className="text-5xl font-bold">Update Partner</h1>

     <div className="flex justify-between ml-4">
     <div className="p-4">
      {partner ? (
        <div className="bg-gray-100  shadow-md rounded p-4">
          <h2 className="text-xl font-bold mb-4">Partner Details</h2>
          <p><strong>Company Name:  </strong> {partner.companyname}</p>
          <p><strong>Contact Person:</strong> {partner.contactperson}</p>
          <p><strong>Partner Code:  </strong>{partner.partnercode}</p>
          <p><strong>Partner email:  </strong>{partner.email}</p>


        </div>
      ) : (
        <div>No partner found for the stored partner code.</div>
      )}
    </div>
    <div className="p-4">
      {partner ? (
        <div className="bg-gray-100  shadow-md rounded p-2">
          <h2 className="text-xl font-bold mb-4">Partner Information</h2>
      
          <p><strong>Balance:</strong> ${partner.balance}</p>
          <p><strong>Discount:</strong> {partner.discount}%</p>

          <p><strong> Type:  </strong>{partner.role}</p>

        </div>
      ) : (
        <div>No partner found for the stored partner code.</div>
      )}
    </div>
</div>

<div className='space-y-4'>
  {/* Only render this div if userRole is 'finance' */}
  {userRole === 'finance' && (
    <div className='flex items-center justify-between bg-gray-100 p-4 rounded-lg shadow-md'>
      <h1 className='text-lg font-medium'>Click here to Update Wallet Amount.</h1>
      <button
        className='rounded-full px-6 py-2 text-white shadow-md hover:bg-white transition duration-200 ease-in'
        style={{ background: 'linear-gradient(to bottom right, #27d0b6, #24c8b5, #1bb1af, #18a8ad)' }}
        onClick={(e) => {
          e.stopPropagation();
          handleEyeClick(partnerCode);
        }}
      >
        Update Wallet
      </button>
    </div>
  )}

  {/* Only render this div if userRole is 'admin' */}
  {userRole === 'admin' && (
    <>
      <div className='flex items-center justify-between bg-gray-100 p-4 rounded-lg shadow-md'>
        <h1 className='text-lg font-medium'>Click here to Update Discount.</h1>
        <button
          className='rounded-full px-6 py-2 font-semibold text-white shadow-md hover:bg-white transition duration-200 ease-in'
          style={{ background: 'linear-gradient(to bottom right, #27d0b6, #24c8b5, #1bb1af, #18a8ad)' }}
          onClick={e => {
            e.stopPropagation();
            handleDiscountClick(partnerCode);
          }}
        >
          Update Discount
        </button>
      </div>

      <div className='flex items-center justify-between bg-gray-100 p-4 rounded-lg shadow-md'>
        <h1 className='text-lg font-medium'>Click here to update program type.</h1>
        <button
          className='rounded-full px-6 py-2 font-semibold text-white shadow-md hover:bg-white transition duration-200 ease-in'
          style={{ background: 'linear-gradient(to bottom right, #27d0b6, #24c8b5, #1bb1af, #18a8ad)' }}
          onClick={e => {
            e.stopPropagation();
            handlePartnerClick(partnerCode);
          }}
        >
          Update Partner
        </button>
      </div>

      <div className='flex items-center justify-between bg-gray-100 p-4 rounded-lg shadow-md'>
        <h1 className='text-lg font-medium'>Click here to Update Partners Plans.</h1>
        <button
          className='rounded-full px-6 py-2 font-semibold text-white shadow-md hover:bg-white transition duration-200 ease-in'
          style={{ background: 'linear-gradient(to bottom right, #27d0b6, #24c8b5, #1bb1af, #18a8ad)' }}
          onClick={handlenavigatevendor}
        >
          Update Plans
        </button>
      </div>


      <div className='flex items-center justify-between bg-gray-100 p-4 rounded-lg shadow-md'>
        <h1 className='text-lg font-medium'>Click here to download the history of the partner</h1>
        <button
          className='rounded-full px-6 py-2 font-semibold text-white shadow-md hover:bg-white transition duration-200 ease-in'
          style={{ background: 'linear-gradient(to bottom right, #27d0b6, #24c8b5, #1bb1af, #18a8ad)' }}
          onClick={downloadReport}
        >
          Download
        </button>
      </div>
    </>
  )}
</div>


    
  </div>

      {/* Modal for updating wallet balance */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 rounded">
            <h2 className="text-xl mb-4">Update Wallet Balance</h2>
            <input
                type="number"
                className="border p-2 mb-4 w-full"
                value={addBalance}
                onChange={(e) => {  setAddBalance(e.target.value)
                 
                }}
                placeholder="Enter amount to add"
              />
              {addBalance && parseFloat(addBalance) <= 2 && (
                <p className="text-red-500">The amount must be greater than 2.</p>
              )}
                        <div className="flex justify-end space-x-4">

            <button
               className='rounded-full px-6 py-2 text-white shadow-md hover:bg-white transition duration-200 ease-in'
               style={{ background: 'linear-gradient(to bottom right, #27d0b6, #24c8b5, #1bb1af, #18a8ad)' }}
              onClick={handleSubmit}
            >
              Submit
            </button>
            <button
              className="bg-red-500 rounded-full px-6 py-2 text-white "
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </button>
            </div>
          </div>
        </div>
      )}

      {isDiscountModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 rounded">
            <h2 className="text-xl mb-4">Update Discount</h2>
            <input
              type="text"
              className="border p-2 mb-4 w-full"
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
              placeholder="Enter discount"
            />
                        <div className="flex justify-end space-x-4">

            <button
              className='rounded-full px-6 py-2 text-white shadow-md hover:bg-white transition duration-200 ease-in'
              style={{ background: 'linear-gradient(to bottom right, #27d0b6, #24c8b5, #1bb1af, #18a8ad)' }}
              onClick={handleDiscountSubmit}
            >
              Submit
            </button>
            <button
              className="bg-red-500 rounded-full px-6 py-2 text-white "
              onClick={() => setIsDiscountModalOpen(false)}
            >
              Cancel
            </button>
            </div>
          </div>
        </div>
      )}




         {/* Modal for Changing Partner Type */}
         {ispartnerModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <h2 className="text-xl font-bold mb-4">Change Partner Type</h2>
            <div className="mb-4">
              <label className="block text-gray-700">Select Type</label>
              <select
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                className="border-2 border-gray-300 rounded p-2 w-full"
              >
                <option value="">Select Type</option>
                <option value="1">Bundled Program</option>
                <option value="2">Pay As Go</option>
              </select>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                className='rounded-full px-6 py-2 text-white shadow-md hover:bg-white transition duration-200 ease-in'
                style={{ background: 'linear-gradient(to bottom right, #27d0b6, #24c8b5, #1bb1af, #18a8ad)' }}
                onClick={handleTypeChange}
              >
                Save
              </button>
              <button
              className="bg-red-500 rounded-full px-6 py-2 text-white "
              onClick={() => setIspartnerModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SuperadminUpdate;

