import React, { useState, useEffect } from "react";
import { IoMdSearch } from "react-icons/io";
import { GoDownload } from "react-icons/go";
import { CiEdit } from "react-icons/ci";

const Superadminpay = () => {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const sessionToken = await fetchSessionToken();
        const role = localStorage.getItem("role");

        if (sessionToken && role === "admin") {
          setIsAdmin(true);


          const response = await fetch(`${apiUrl}ESIMGOAdmin/partner_details?flag=1`, {
            headers: {
              "Authorization": `Bearer ${sessionToken}`,
            },
          });

          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }

          const data = await response.json();
          setPartners(data.partnerlist);
        } else {
          setIsAdmin(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPartners();
  }, []);

  const fetchSessionToken = async () => {
    let sessionToken = localStorage.getItem("token");
    return sessionToken;
  };

  const handleTypeChange = async () => {
    if (!selectedPartner || !selectedType) return;
  
    const payload = {
      partnercode: selectedPartner.partnercode,
      type: selectedType,
    };
  
    console.log("Payload being sent:", payload);
  
    try {
      const sessionToken = await fetchSessionToken();
      const response = await fetch(`${apiUrl}ESIMGOAdmin/UpdatePartner_type`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${sessionToken}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error("Failed to update partner type");
      }
  
      // Update local state or refetch data if necessary
      alert("Partner type updated successfully");
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error updating partner type:", error);
      alert("Failed to update partner type");
    }
  };
  
  const filteredPartners = partners.filter(
    (partner) =>
      partner.partnercode.toLowerCase().includes(searchTerm.toLowerCase()) ||
      partner.companyname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      partner.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAdmin) {
    return <div>You do not have permission to view this page.</div>;
  }

  return (
    <div className="mt-4 bg-white shadow p-4 justify-between items-center rounded-3xl">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-5xl font-bold">Purchase Details</h1>
        <div className="flex items-center space-x-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Search by partner code, name, or email"
              className="border-2 bg-[#7bc2c5] rounded-full p-2 pl-10 w-96 text-[#105255] placeholder-[#105255]"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              <IoMdSearch size={20} className="text-[#105255]" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 bg-white shadow p-4 flex justify-between items-center rounded-md">
        <div className="relative">
          {filteredPartners.length > 0 ? (
            <table className="w-full bg-white rounded-md h-96">
              <thead className="bg-gray-50">
                <tr>
                  <th className="text-center px-4 py-4">Partner Code</th>
                  <th className="text-center px-4 py-4">Email</th>
                  <th className="text-center px-4 py-4">Contact Person</th>
                  <th className="text-center px-4 py-4">Type </th>

                  {/* <th className="text-center px-4 py-4">Discount</th> */}
                  <th className="text-center px-4 py-4">Balance</th>
                  <th className="text-center px-4 py-4">Partner Type</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredPartners.map((partner) => (
                  <tr key={partner.partnerid}>
                    <td className="text-center px-4 py-4">{partner.partnercode}</td>
                    <td className="text-center px-4 py-4">{partner.email}</td>
                    <td className="text-center px-4 py-4">{partner.contactperson}</td>
                    <td className="text-center px-4 py-4 ">{partner.role}</td>

                    {/* <td className="text-center px-4 py-4">{partner.discount}%</td> */}
                    <td className="text-center px-4 py-4">${partner.balance}</td>
                    <td className="text-center px-4 py-4">
                      <button
    className="w-full text-center rounded-md text-[#105255] bg-[#7bc2c5]  px-4 py-4 shadow-md hover:bg-white transition duration-200 ease-in"
                     onClick={() => {
                          setSelectedPartner(partner);
                          setIsModalOpen(true);
                        }}
                      >
                        Partner_Type
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="text-red-500 font-bold text-center px-4 py-4 mt-4">
              Partner code, partner name, or email does not match.
            </div>
          )}
        </div>
      </div>

      {/* Modal for Changing Partner Type */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <h2 className="text-xl font-bold mb-4">Change Partner Type</h2>
            <div className="mb-4">
              <label className="block text-gray-700">Select Type</label>
              <select
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                className="border-2 border-gray-300 rounded p-2 w-full"
              >
                <option value="">Select Type</option>
                <option value="1">Bundled Program</option>
                <option value="2">Pay As Go</option>
              </select>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={handleTypeChange}
              >
                Save
              </button>
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Superadminpay;