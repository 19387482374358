import React from 'react';
import Cookies from '../assets/images/Terms&Policy/Cookies.svg';
import Data from '../assets/images/Terms&Policy/Data.svg';
import Message from '../assets/images/Terms&Policy/Message.svg';
import Pro from '../assets/images/Terms&Policy/Pro.svg';
import Profile from '../assets/images/Terms&Policy/Profile.svg';
import Right from '../assets/images/Terms&Policy/Rights.svg';
import Shield from '../assets/images/Terms&Policy/Shield.svg';
import Terms from '../assets/images/Terms&Policy/Terms.svg';
import TermsP from '../assets/images/Terms&Policy/Terms&Condtions.svg';

const PrivacyPolicy = () => {
  return (
    <div className="bg-gray-100 min-h-screen flex justify-center items-center">
      <div className="bg-white max-w-4xl w-full rounded-lg shadow-lg p-4">
        <div className="bg-teal-700 text-white text-center rounded-t-lg">
          <img src={TermsP} className="w-full" alt="Terms and Conditions" />
        </div>

        <div className="mt-4 text-gray-700 text-center">
          <p>
            This Privacy Policy explains how Airhub collects, uses, and protects your information on the <strong>B2B Partner Portal</strong>.
          </p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6">
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200">
            <div className="flex mr-2">
              <img src={Message} alt="message" />
              <h3 className="text-lg font-bold mb-2 text-[#1a5659] m-2">Information We Collect:</h3>
            </div>
            <p>- Personal Information: Name, contact details, business information.</p>
            <p>- Transaction Data: Details of transactions conducted on the portal.</p>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200">
            <div className="flex mr-2">
              <img src={Profile} alt="profile" />
              <h3 className="text-lg font-bold mb-2 text-[#1a5659] m-2">How We Use Your Information:</h3>
            </div>
            <p>- To facilitate your use of the portal and process transactions.</p>
            <p>- To send important updates about your account and transactions.</p>
            <p>- To enhance the portal's functionality and user experience.</p>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200">
            <div className="flex mr-2">
              <img src={Shield} alt="shield" />
              <h3 className="text-lg font-bold mb-2 text-[#1a5659] m-2">Sharing Your Information:</h3>
            </div>
            <p>- We may share information with service providers for business operations.</p>
            <p>- Information may be disclosed to comply with legal obligations.</p>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200">
            <div className="flex mr-2">
              <img src={Right} alt="rights" />
              <h3 className="text-lg font-bold mb-2 text-[#1a5659] m-2">Your Rights:</h3>
            </div>
            <p>- Request access to or correction of your personal data.</p>
            <p>- Request deletion of your personal data, subject to legal requirements.</p>
          </div>
        </div>

        <div className="mt-6 space-y-4">
          <div className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200">
            <div className="flex mr-2">
              <img src={Data} alt="data" />
              <h3 className="text-lg font-bold mb-2 text-[#1a5659] m-2">Data Security:</h3>
            </div>
            <p>We use industry-standard security measures to protect your information.</p>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200">
            <div className="flex mr-2">
              <img src={Cookies} alt="cookies" />
              <h3 className="text-lg font-bold mb-2 text-[#1a5659] m-2">Cookies and Tracking:</h3>
            </div>
            <p>We use cookies to improve your experience on the portal. You can manage cookie settings in your browser.</p>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200">
            <div className="flex mr-2">
              <img src={Terms} alt="terms" />
              <h3 className="text-lg font-bold mb-2 text-[#1a5659] m-2">Changes to Terms:</h3>
            </div>
            <p>We may update this policy periodically. Continued use of the portal indicates acceptance of the new policy.</p>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200">
            <div className="flex mr-2">
              <img src={Pro} alt="contact" />
              <h3 className="text-lg font-bold mb-2 text-[#1a5659] m-2">Contact Information:</h3>
            </div>
            <p>For questions about these policies, contact us at <strong>support@airhubapp.com</strong></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
