import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import Coin from '../assets/images/coin.svg';
import { useNavigate } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import { GoDownload } from "react-icons/go";
import QRCode from 'qrcode.react'; // Import QR code library
import { saveAs } from 'file-saver';
import PayAsYouGO from '../assets/images/Home.png'
import { ImCross } from "react-icons/im";

const PlanCountRegionwise = () => {
  const [planData, setPlanData] = useState([]);
  const [purchasePlanData, setPurchasePlanData] = useState([]);
  const [walletData, setWalletData] = useState(null);
  const [sessionToken, setSessionToken] = useState(null);
  const [partnerCode, setPartnerCode] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Initialize with current month
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [selectedLpaCode, setSelectedLpaCode] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable
  const [userRole, setUserRole] = useState(null); // State for user role
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState(''); // State to manage dropdown filter

  useEffect(() => {
    // Fetch user role from localStorage
    const role = localStorage.getItem("role");
    setUserRole(role);
  }, []);
  const itemsPerPage =5;

  const navigate = useNavigate();

  const handletopups = () => {
    navigate('/AddmoreBalance');
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    const partnerCode = localStorage.getItem('partnerCode');

    if (token) {
      axios.get(`${apiUrl }ESIM/Get_plancount_regionwise?flag=1`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        if (response.data.isSuccess) {
          setPlanData(response.data.planlists);
        }
      })
      .catch(error => {
        console.error('Error fetching the data', error);
      });

      axios.get(`${apiUrl }ESIM/partner_purchaseplan?partnercode=${partnerCode}&month=${selectedMonth}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        if (response.data.isSuccess) {
          setPurchasePlanData(response.data.purchseplanlist);
        }
      })
      .catch(error => {
        console.error('Error fetching the purchase plan data', error);
      });
    } else {
      console.error('No token found in session storage');
    }
  }, [selectedMonth]);

  useEffect(() => {
    const fetchSessionData = () => {
      const storedSessionToken = localStorage.getItem('token');
      const storedPartnerCode = localStorage.getItem('partnerCode');
      const storedRole = localStorage.getItem('role');

      if (storedSessionToken && storedPartnerCode && storedRole) {
        setSessionToken(storedSessionToken);
        setPartnerCode(storedPartnerCode);
        setIsAdmin(storedRole === 'admin');
      } else {
        console.error('Session data not found');
      }
    };

    fetchSessionData();
  }, []);

  useEffect(() => { 
    const fetchWalletData = async () => {
      const sessionToken  = localStorage.getItem('token');
      const  partnerCode= localStorage.getItem('partnerCode');
      try {
        if (!sessionToken || !partnerCode) {
          console.error('Session token or partner code missing');
          return;
        }

        const response = await axios.get(
          `${apiUrl }ESIMGOPartner/get_wallet_invidual?partnercode=${partnerCode}`,
          {
            headers: {
              Authorization: `Bearer ${sessionToken}`,
            },
          }
        );
        setWalletData(response.data.getindividualwallet[0]);
      } catch (error) {
        console.error('Error fetching wallet data:', error);
      }
    };

    fetchWalletData();
  }, [sessionToken, partnerCode]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleDownload = (lpacode) => {
    // Simulate downloading logic here
    // For demonstration purposes, download a dummy QR code image
    const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=${lpacode}&size=200x200`;
    saveAs(qrCodeUrl, `QR_${lpacode}.png`);
  };



  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const token = localStorage.getItem('token');
        const partnerCode = localStorage.getItem('partnerCode');
        const response = await fetch(    `${apiUrl }ESIM/Get_purchasereport_sourcewise?flag=3&partnercode=${partnerCode}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        if (data.isSuccess) {
          setReportData(data.partnersale);
        } else {
          setError(data.message);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchReportData();
  }, []);

  const openModal = (plan) => {
    setSelectedPlan(plan);
    setQrModalOpen(true);
  };

  const closeModal = () => {
    setQrModalOpen(false);
    setSelectedPlan(null);
  };
  const filteredReportData = filter ? reportData.filter(sale => sale.saletype === filter) : reportData;


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Pagination logic
  const startIndex = (currentPage - 1) * itemsPerPage;
  const selectedPlans = purchasePlanData.slice(startIndex, startIndex + itemsPerPage);
  const totalPages = Math.ceil(purchasePlanData.length / itemsPerPage);

  return (
    <div className="container mx-auto ">
      <div className="flex flex-wrap">
    
      <div className="w-full lg:w-2/3 p-2 flex">
  <div className="bg-white shadow rounded-lg p-4 flex-grow">
    <h2 className="text-5xl font-bold mb-4">Available Regional Plans</h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      {planData.map((plan, index) => (
        <div 
          key={index} 
          className="flex justify-between items-center h-14 w-46 bg-[#dbedec] rounded-lg">
          <div className="flex-1 text-center text-[#3a9a8a]">
            <span className="font-sm text-lg">
              {plan.regionname ? plan.regionname : 'Country Plans'}
            </span>
          </div>
          <div className="m-2 p-2 w-16 text-center text-[#117575] font-bold bg-[#ffffff] rounded-lg">
            {plan.plancount}
          </div>
        </div>
      ))}
    </div>
  </div>
</div>


<div className="w-full lg:w-1/3 p-2 flex">
  <div className="bg-white shadow rounded-lg p-4 flex-grow">
    <h1 className="text-5xl font-bold mb-4 flex justify-between items-center">
      {userRole === "Partner" ? (
        <>
          Wallet Balance <img src={Coin} alt="coin" />
        </>
      ) : (
        "Pay As You Go"
      )}
    </h1>
    <div className="text-center my-4 mb-8 mt-8">
      {userRole === "Partner" ? (
        <div style={{ fontSize: '4.0rem' }} className="font-bold text-[#117575]">
          {walletData ? (
            <p className="text-2xl font-bold">${walletData.balance}</p>
          ) : (
            <p className="text-lg text-gray-600">Loading...</p>
          )}
        </div>
      ) : (
        <div style={{ fontSize: '2.0rem' }} className="font-bold text-[#117575]">
 <img src={PayAsYouGO}
          className='mr-2 w-80 h-64 '
        />        </div>
      )}
    </div>
    {userRole === "Partner" && (

    <button className="text-[#595959] w-full flex justify-center items-center border border-black px-4 py-2 rounded mb-8" onClick={handletopups}>
      Add more balance <MdOutlineKeyboardArrowRight size={40} />
    </button>
    )}
  </div>
</div>





      </div>

      <div className="mt-4 bg-white shadow rounded-t-lg p-4 flex justify-between items-center">
        <h2 className="text-5xl font-bold ">Purchased Plans</h2>
        <div className="relative">
          <select
            className="bg-[#1ebab1] text-white p-2 rounded"
            value={selectedMonth}
            onChange={handleMonthChange}
          >
            <option value={1}>January</option>
            <option value={2}>February</option>
            <option value={3}>March</option>
            <option value={4}>April</option>
            <option value={5}>May</option>
            <option value={6}>June</option>
            <option value={7}>July</option>
            <option value={8}>August</option>
            <option value={9}>September</option>
            <option value={10}>October</option>
            <option value={11}>November</option>
            <option value={12}>December</option>
          </select>
        </div>
      </div>

      {selectedPlans.length === 0 ? (
                <p className="text-lg bg-[#ffffff] font-extrabold text-center h-56 text-gray-600">No Active Packages</p>
              ) : (
                <table className="w-full bg-white rounded-md">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-center">Order ID</th>
                    <th className="px-6 py-3 text-center">Plan Name</th>
                  {userRole == "Partner" && (
                    <th className="px-6 py-3 text-center">Purchase Amount</th>
                  )}
                    <th className="px-6 py-3 text-center">Purchase Date</th>
                    <th className="px-6 py-3 text-center">Actions</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {filteredReportData.length === 0 ? (
                    <tr>
                      <td colSpan="6" className="text-center py-4">
                        <p className="text-lg font-bold">No Active Packages</p>
                      </td>
                    </tr>
                  ) : (
                    filteredReportData.map((sale, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 text-center">{sale.order_id}</td>
                        <td className="px-6 py-4 text-center">{sale.planname}</td>
                        {userRole == "Partner" && (
        
                        <td className="px-6 py-4 text-center">${parseFloat(sale.purchaseamount).toFixed(2)}</td>
                        )}
                        <td className="px-6 py-4 text-center">{sale.purchasedate}</td>
                        <td className="px-6 py-4 flex text-center">
                          <button
                            className="text-black font-bold py-2 px-4 rounded space-x-2"
                            onClick={() => handleDownload(sale.activationCode)}
                          >
                            <GoDownload />
                          </button>
                          <button
                            className="text-black font-bold py-2 px-4 rounded space-x-2"
                            onClick={() => openModal(sale)}
                          >
                            <FaEye />
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
)}


      {/* Modal for QR Code */}
      {qrModalOpen && selectedPlan && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-md flex flex-row relative">
            {/* Left column */}
            <div className="flex flex-col items-start w-96">
              <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
                <span className="font-bold">Order ID:</span>{' '}
                <span className="ml-4">{selectedPlan?.order_id || 'N/A'}</span>
              </div>
              <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
                <span className="font-bold">APN:</span>{' '}
                <span className="ml-4">{selectedPlan?.apn || 'N/A'}</span>
              </div>
              <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
                <span className="font-bold">ICCID:</span>{' '}
                <span className="ml-4">{selectedPlan?.iccid || 'N/A'}</span>
              </div>
              <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
                <span className="font-bold">SIMPIN:</span>{' '}
                <span className="ml-4">{selectedPlan?.simpin || 'N/A'}</span>
              </div>
            
              <div className="mb-4 p-4 w-full border-4 bg-slate-200 rounded-2xl">
                <span className="font-bold"> Activation code:</span>{' '}
                <span className="ml-4">{selectedPlan?.activationCode || 'N/A'}</span>
              </div>
              <div className="mb-4 p-4 w-full border-4 bg-[#c5f1f1] rounded-2xl">
                <span className="font-bold">How to Install ?</span>
                <a href="https://www.airhubapp.com/info/Installation" target="_blank" rel="noopener noreferrer" className="ml-4 border-2 rounded-2xl hover:bg-blue-400 p-2 border-black">Step by Step Guide</a>
              </div>
            </div>

            {/* Right column */}
            <div className="flex flex-col items-center justify-center ml-8">
              <QRCode value={selectedPlan.activationCode} className='border-4 p-2 border-[#47a192]' size={200} />
              <button
                className="text-[#595959] flex items-center border border-black px-4 py-2 rounded mt-4"
                onClick={() => handleDownload(selectedPlan.activationCode)}
              >
                <GoDownload size={30} /> Download
              </button>
            </div>

            {/* Close button */}
            <ImCross size={30} onClick={closeModal} className="cursor-pointer absolute top-2 right-2" />
          </div>
        </div>
      )}

      <div className="bg-[#ffffff] flex justify-center">
        <div className="flex space-x-2">
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              className={`px-3 py-1 rounded ${index + 1 === currentPage ? 'bg-blue-500 text-white' : 'bg-white'}`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlanCountRegionwise;
