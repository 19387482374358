import React, { useState, useEffect } from "react";
import axios from "axios";
import Plan from "./Plan"; // Import the Plan component
import Modal from "react-modal"; // Import react-modal for handling modals
import { IoMdSearch } from "react-icons/io";

import africa from "../assets/images/Buydatamap/africa.svg";
import asia from "../assets/images/Buydatamap/asia.svg";
import caribbean from "../assets/images/Buydatamap/caribbean.svg";
import europe from "../assets/images/Buydatamap/europe.svg";
import global from "../assets/images/Buydatamap/global.svg";
import MiddleEast from "../assets/images/Buydatamap/middleeast.svg";
import NorthAmerica from "../assets/images/Buydatamap/northamerica.svg";
import SouthLationAmerica from "../assets/images/Buydatamap/southlatingamerica.svg";
import { GoDownload } from "react-icons/go";
import { Navigate, useNavigate } from "react-router-dom";
import Local from '../assets/images/local.svg';
import Regional from '../assets/images/Regional.svg'
const BuyDataBundlesAPI = () => {
  const [regions, setRegions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [selectedRegionId, setSelectedRegionId] = useState('4'); // Default to region ID 3
  const [selectedCountryId, setSelectedCountryId] = useState('28'); // Default to country ID 1
  const [plans, setPlans] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [loadingPlans, setLoadingPlans] = useState(false);
  const [error, setError] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false); // State for modal visibility
  const [selectedPlan, setSelectedPlan] = useState(null); // State for selected plan details
  const [regionss, setregionss] = useState([]);
  const [selectedRegionss, setselectedRegionss] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const apiUrl = process.env.REACT_APP_BASE_URL; // Use the environment variable
const [selected,setSelected]=useState();

  const [userRole, setUserRole] = useState(null); // State for user role
const navigate= useNavigate();


  useEffect(() => {
    // Fetch user role from localStorage
    const role = localStorage.getItem("role");
    setUserRole(role);
  }, []);
  useEffect(() => {
    const fetchregionss = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${apiUrl}ESIM/Get_plancount_regionwise?flag=1`,
          {
            headers: {
                         'Authorization': `Bearer ${token}`

            },
          }
        );
        if (response.data.isSuccess) {
          setregionss(response.data.planlists);
        }
      } catch (error) {
        console.error("Error fetching regionss:", error);
      }
    };

    fetchregionss();
  }, []);

  const handleRegionClick = async (regionId, regionName) => {
    setselectedRegionss(regionName);

    try {
      const token = localStorage.getItem("token");
      const partnerCode = localStorage.getItem("partnerCode");
      const response = await axios.post(
        `${apiUrl}ESIM/Get_plan_byregion`,
        {
          region_id: regionId,
          country: "string",
          partnerCode: partnerCode,
        },
        {
          headers: {
                       'Authorization': `Bearer ${token}`

          },
        }
      );
      if (response.data.isSuccess) {
        setPlans(response.data.getofflineplan);
      }
    } catch (error) {
      console.error("Error fetching plans:", error);
    }
  };
  useEffect(() => {
    // Fetch regions on component mount
    fetchRegions();
  }, []);

  useEffect(() => {
    // Fetch countries when selectedRegionId changes
    if (selectedRegionId) {
      fetchCountries(selectedRegionId);
      // Reset selected country and plans when region changes
      setSelectedCountryId("28"); // Reset selected country to default
      setPlans([]); // Clear plans
    }
  }, [selectedRegionId]);

  useEffect(() => {
    // Fetch plans when selectedCountryId changes
    if (selectedCountryId) {
      fetchPlans(selectedRegionId, selectedCountryId);
    }
  }, [selectedCountryId]);
  localStorage.setItem("selectedRegionId", selectedRegionId);

  // Function to fetch regions from API
  const fetchRegions = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
       `${apiUrl}ESIM/GetRegion_detail?flag=5`,
        {
          headers: {
                       'Authorization': `Bearer ${token}`

          },
        }
      );
      setRegions(response.data.getregion);
    } catch (error) {
      alert("Error fetching regions:", error);
    }
  };

  // Function to fetch countries by region ID using POST API request
  const fetchCountries = async (regionId) => {
    setLoadingCountries(true);
    try {
      const token = localStorage.getItem("token");
      const partnerCode = localStorage.getItem("partnerCode");

      const postData = {
        region_id: regionId,
        partnerCode: partnerCode,
      };
      const response = await axios.post(
        `${apiUrl}ESIM/Getcountry_byregion`,
        postData,
        {
          headers: {
                       'Authorization': `Bearer ${token}`

          },
        }
      );
      setCountries(response.data.getcountry);
    } catch (error) {
      alert("Error fetching countries:", error);
    } finally {
      setLoadingCountries(false);
    }
  };
  // const downloadCSV = async () => {
  //   const url = 'https://drive.google.com/uc?export=download&id=1KPjZzk1FkfIikkt1K6U0Pyj93ey0iosb'; // Replace with the file's ID for direct download
  
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute('download', 'Plan.csv'); // Optional: Set the downloaded file's name
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link); // Clean up
  // };
  
  // Function to fetch plans by region ID, country ID using API
  const fetchPlans = async (regionId, countryId) => {
    setLoadingPlans(true);
    try {
      const token = localStorage.getItem("token");
      const partnerCode = localStorage.getItem("partnerCode");

      const postData = {
        region_id: regionId,
        country: countryId,
        partnerCode: partnerCode,
      };
      const response = await axios.post(
       `${apiUrl}ESIM/Getplan_bycountry`,
        postData,
        {
          headers: {
                       'Authorization': `Bearer ${token}`

          },
        }
      );
      setPlans(response.data.getofflineplan);
    } catch (error) {
      alert("Error fetching plans:", error);
    } finally {
      setLoadingPlans(false);
    }
  };

  const handleBuyClick = (plan) => {
    localStorage.setItem("planCode", plan.plancode); // Store planid in session storage
    localStorage.setItem("plan_id", plan.planid); // Store planid in session storage

    setSelectedPlan(plan);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const excludeRegions = [];
// Filter out the regions you don't want to display
const filteredRegions = regionss.filter(
  (region) => !excludeRegions.includes(region.regionname)
);
  const filteredCountries = countries.filter((country) =>
    country.countryName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const downloadCSV = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found. Please log in.");
        return;
      }
      const response = await fetch(`${apiUrl}ESIM/Get_all_plans?flag=1`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      });
  
      const data = await response.json();
  
      if (data.isSuccess) {
        let { getallplan } = data;
  
        // Sort the plans alphabetically by country
        getallplan = getallplan.sort((a, b) => a.country.localeCompare(b.country));
  
        // Prepare CSV data
        const header = 'Country,Data,Validity,SellingCost\n';
        const rows = getallplan.map(plan => (
          `${plan.country},${plan.data === "" ? "Unlimited" : plan.data},${plan.validity},${plan.sellingcost}`
        )).join('\n');
  
        const csvContent = header + rows;
  
        // Create a blob and trigger download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'plans.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };
  
  
  const handleNavigation = (storeType) => {
    setSelected(storeType);

    if (storeType === "local") {
      navigate("/BuyDataBundles"); // Navigate to Home
    } else if (storeType === "regional") {
      navigate("/Buydatad"); // Navigate to Regional eSIM
    }
  };
  return (
    <div className="p-2">
      
      <div className="flex">
      <button
        onClick={() => handleNavigation("local")}
        className={`self-stretch shadow-[0px_2px_8px_rgba(92,_152,_140,_0.3)] rounded-lg h-10 flex flex-row items-center justify-center p-2.5 box-border m-4 ${
          selected === "local"
            ? "bg-brand-white text-brand-green-selected"
            : "bg-brand-green-selected text-brand-white"
        }`}
      >
        <img src={Regional}
          className={`mr-2 ${
            selected === "local" ? "text-brand-green-selected" : "text-brand-white"
          }`}
        />
        Local eSIM store
      </button>


      <button
        onClick={() => handleNavigation("regional")}
        className={`self-stretch shadow-[0px_2px_8px_rgba(92,_152,_140,_0.3)] rounded-lg h-10 flex flex-row items-center justify-center p-2.5 box-border m-4 `}
      >
        <img src={Local}
         className="h-6 w-6 filter invert sepia saturate-500 hue-rotate-180 brightness-150 contrast-100"
         alt="Regional Icon"
      
        />
        Regional eSIM store
      </button>
    </div>

      <div className="flex">
        {error && <p className="text-red-500">{error}</p>}
        <div
          className="flex flex-col w-fit-content"
          style={{ maxHeight: "400px", overflowY: "auto" }}
        >
          

          {regions.map((region) => (
            <div
              key={region.id}
              style={{
                backgroundColor:
                  selectedRegionId === region.id ? "#117575" : "#acd8d8",
                color: selectedRegionId === region.id ? "#ffffff" : "#3cabab",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className={`rounded-lg shadow-md p-4 cursor-pointer flex-auto m-1 text-black mr-4 mt-4 ${
                selectedRegionId === region.id
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-700"
              }`}
              onClick={() => setSelectedRegionId(region.id)}
            >
              <div
                style={{ fontSize: "0.9rem" }}
                className="text-lg font-semibold mb-2"
              >
                {region.name}
              </div>
            </div>
          ))}
        </div>
        {menuOpen && (
          <div
            style={{
              backgroundColor: "#f8fafd",
              borderRadius: "2rem",
              maxHeight: "400px",
              overflowY: "auto",
            }}
            className="flex-1 m-2 p-4"
          >
            <div className="relative">
              <input
                type="search"
                placeholder="Search Regions"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full self-stretch rounded-3xl bg-brand-gray h-10 flex flex-row items-center justify-start py-2 px-3 box-border gap-[12px] mr-2 mt-4 pl-10" // added pl-10 for padding left
              />
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <IoMdSearch size={20} className="text-gray-500 to-black" />
              </div>
            </div>
            <h2 className="text-xl font-semibold mt-8">Available Regions</h2>
            {loadingCountries ? (
              <p className="text-center">Loading countries...</p>
            ) : (
              <ul>
              {filteredRegions.map((region) => (
                <li
                  key={region.region_id}
                  onClick={() => handleRegionClick(region.region_id, region.regionname)}
                >
                  <div
                    className={`self-stretch shadow-[0px_2px_8px_rgba(92,_152,_140,_0.3)] rounded-xl ${
                      selectedRegionss === region.regionname
                        ? "bg-brand-green-selected text-brand-white"
                        : "bg-brand-gray text-black"
                    } border-blue-500 h-10 flex flex-row items-center justify-center p-2.5 box-border m-4`}
                  >
                    <button>
                      <div className="relative font-medium">
                        {region.regionname || "Unknown Region"}
                      </div>
                    </button>
                  </div>
                </li>
              ))}
            </ul>
            )}
          </div>
        )}

        {/* Countries List */}
        {selectedRegionId && (
          <div
            style={{
              backgroundColor: "#f8fafd",
              borderRadius: "2rem",
              maxHeight: "400px",
              overflowY: "auto",
            }}
            className="flex-1 m-2 p-4"
          >
            <div className="relative">
              <input
                type="search"
                placeholder="Search Country"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full self-stretch rounded-3xl bg-brand-gray h-10 flex flex-row items-center justify-start py-2 px-3 box-border gap-[12px] mr-2 mt-4 pl-10" // added pl-10 for padding left
              />
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <IoMdSearch size={20} className="text-gray-500 to-black" />
              </div>
            </div>
            <h2 className="text-xl font-semibold mt-8">
              Countries for Selected Region
            </h2>
            {loadingCountries ? (
              <p className="text-center">Loading countries...</p>
            ) : (
              <ul>
                {filteredCountries.length === 0 ? (
                  <p className="text-center text-red-500 font-bold text-5xl">
                    No countries found in this region. Please select another
                    region.
                  </p>
                ) : (
                  filteredCountries.map((country) => (
                    <li key={country.id}>
                      <div
                        className={`self-stretch shadow-[0px_2px_8px_rgba(92,_152,_140,_0.3)] rounded-xl ${
                          selectedCountryId === country.id
                            ? "bg-brand-green-selected text-brand-white"
                            : "bg-brand-gray text-black"
                        } border-blue-500 h-10 flex flex-row items-center justify-center p-2.5 box-border m-4`}
                      >
                        <button
                          onClick={() => setSelectedCountryId(country.id)}
                        >
                          <div className="relative font-medium">
                            {country.countryName}
                          </div>
                        </button>
                      </div>
                    </li>
                  ))
                )}
              </ul>
            )}
          </div>
        )}

        <div
          style={{ backgroundColor: "#f8fafd", borderRadius: "2rem" }}
          className="flex-1 mr-2 p-4 w-96 text-5xl font-bold text-[#197979] flex flex-col items-center justify-center"
        >
          {selectedRegionId == 1 && <h1 className="text-center">Africa</h1>}
          {selectedRegionId == 1 && (
            <img src={africa} alt="africa" className="mx-auto" />
          )}

          {selectedRegionId == 3 && <h1 className="text-center">Asia</h1>}
          {selectedRegionId == 3 && (
            <img src={asia} alt="asia" className="mx-auto" />
          )}

          {selectedRegionId == 12 && <h1 className="text-center">Caribbean</h1>}
          {selectedRegionId == 12 && (
            <img src={caribbean} alt="caribbean" className="mx-auto" />
          )}

          {selectedRegionId == 4 && <h1 className="text-center">Europe</h1>}
          {selectedRegionId == 4 && (
            <img src={europe} alt="europe" className="mx-auto" />
          )}

          {selectedRegionId == 8 && <h1 className="text-center">Global</h1>}
          {selectedRegionId == 8 && (
            <img src={global} alt="global" className="mx-auto" />
          )}

          {selectedRegionId == 5 && (
            <h1 className="text-center">Middle East</h1>
          )}
          {selectedRegionId == 5 && (
            <img src={MiddleEast} alt="middleeast" className="mx-auto" />
          )}

          {selectedRegionId == 6 && (
            <h1 className="text-center">North America</h1>
          )}
          {selectedRegionId == 6 && (
            <img src={NorthAmerica} alt="northamerica" className="mx-auto" />
          )}

          {selectedRegionId == 7 && (
            <h1 className="text-center">South Latin America</h1>
          )}
          {selectedRegionId == 7 && (
            <img
              src={SouthLationAmerica}
              alt="southlatinamerica"
              className="mx-auto"
            />
          )}

          {selectedRegionId == false && (
            <h1 className="text-center">Regional</h1>
          )}
          {selectedRegionId == false && (
            <img src={global} alt="global" className="mx-auto" />
          )}
        </div>
      </div>
      <div
        style={{ backgroundColor: "#f8fafd" }}
        className="text-black rounded-md bg-e9f8f9 p-2 mt-4"
      >
        <div className="flex items-center justify-between mb-4">
          <h1 className="text-xl font-bold"> All Packages</h1>

          <button
      onClick={downloadCSV}
      className="px-4 py-2 text-[#105255] rounded-md font-bold flex items-center space-x-2"
    >
      <GoDownload size={28} />
      <span>Export</span>
    </button>   
         </div>
        {loadingPlans ? (
          <p className="text-center my-4">Loading plans...</p>
        ) : (
          <table className="w-full">
            <thead className="py-2 px-4 mb-4">
              <tr className="border-b">
              <th className="text-center p-3 px-5">Country/Region</th>
                <th className="text-center p-3 px-5">Data</th>
                <th className="text-center p-3 px-5">Validity (Days)</th>
                {/* <th className="text-center p-3 px-5">Plan Name</th> */}
                <th className="text-center p-3 px-5">Plan Code</th>
                {userRole === 'Partner' && (
                    <>
                      <th className="text-center p-3 px-5">Discount (%)</th>
                      <th className="text-center p-3 px-5">Selling Cost</th>
                    </>
                  )}
                <th className="text-center p-3 px-5">Buy</th>
              </tr>
            </thead>

            <tbody>
              {plans.length === 0 && (
                <tr>
                  <td colSpan="5" className="text-center">
                  Please Select The Country.                  </td>
                </tr>
              )}
              {plans.map((plan) => (
                <tr key={plan.planid} className="border-b hover:bg-gray-100">
                 <td className="text-center p-3 px-5">{plan.geography}</td>
                 <td className="text-center p-3 px-5">
                 {plan.data !== "" ? plan.data : "Unlimited"}
                 </td>

                 <td className="text-center p-3 px-5">{plan.vaildity} </td>
                 {/* <td className="text-center p-3 px-5">{plan.planName}</td> */}
                  <td className="text-center p-3 px-5">{plan.plancode}</td>
                  {userRole === 'Partner' && (
                    <>
                      <td className="text-center p-3 px-5">{plan.markuDiscount * 100}%</td>
                      <td className="text-center p-3 px-5">${plan.sellingcost}</td>
                    </>
                  )}


                  <td className="text-center ">
                    <div className="self-stretch shadow-[0px_2px_8px_rgba(92,_152,_140,_0.3)] rounded-xl bg-brand-green-selected  border-blue-500 h-10 flex flex-row items-center justify-center p-2.5 box-border text-brand-white m-4">
                      <button onClick={() => handleBuyClick(plan)}>Buy</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "80%",
            margin: "auto",
            padding: "0px",
            borderRadius: "8px",
            height: "fit-content",
          },
        }}
      >
        {selectedPlan && <Plan plan={selectedPlan} closeModal={closeModal} />}
      </Modal>
    </div>
  );
};

export default BuyDataBundlesAPI;