import React, { useState, useEffect } from "react";
import axios from "axios";

const VendorList = () => {
  const [vendors, setVendors] = useState([]);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false); // Manage modal visibility
  const [selectedVendor, setSelectedVendor] = useState(null); // Track the selected vendor
  const [statusFlag, setStatusFlag] = useState(0); // Manage status flag for updating
  const [apiMessage, setApiMessage] = useState(""); // Capture API message
  const [partner, setPartner] = useState(null);
  const [loading, setLoading] = useState(true);

  const apiUrl = process.env.REACT_APP_BASE_URL;

  // Function to fetch data from the API
  const fetchVendors = async () => {
    try {
      const token = localStorage.getItem("token"); // Retrieve token from local storage
    const selectedPartnerCode = localStorage.getItem('selectedPartnerCode');

      if (!token) {
        setError("Token not found. Please log in.");
        return;
      }

      const response = await axios.get(
        `${apiUrl}ESIMGOAdmin/Get_map_Partner_list?partnerCode=${selectedPartnerCode}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Attach the token to the Authorization header
          },
        }
      );

      if (response.data.isSuccess) {
        setVendors(response.data.mappedpartnerlist); // Update vendors array with API response
      } else {
        setError("Failed to fetch vendors");
      }
    } catch (err) {
      setError("An error occurred while fetching the data");
    }
  };

  // Function to open the modal with the selected vendor
  const openModal = (vendor) => {
    setSelectedVendor(vendor);
    setStatusFlag(vendor.status === "1" ? 1 : 0); // Set the initial flag based on the current status
    setModalOpen(true);
    setApiMessage(""); // Reset API message when opening the modal
  };

  // Function to update partner status via API
  const updateStatus = async () => {
    try {
      const token = localStorage.getItem("token"); // Retrieve token from local storage

      if (!token) {
        setError("Token not found. Please log in.");
        return;
      }

      const payload = {
        flag: statusFlag,
        map_id: selectedVendor.id, // Use the selected vendor's id
      };

      const response = await axios.post(
       `${apiUrl}ESIMGOAdmin/update_mapped_partnerstaus`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Attach the token to the Authorization header
          },
        }
      );

      if (response.data.isSuccess) {
        setApiMessage(response.data.message); // Capture the success message
        // Refresh the vendor list
        fetchVendors();
      } else {
        setApiMessage(response.data.message || "Failed to update vendor status");
      }
    } catch (err) {
      setApiMessage("An error occurred while updating the status");
    }
  };

  useEffect(() => {
    fetchVendors();
  }, []);
  useEffect(() => {
    const fetchPartnerDetails = async () => {
      try {
        const token = localStorage.getItem('token'); // Replace with your actual token key

        const response = await axios.get(`${apiUrl}ESIMGOAdmin/partner_details?flag=1`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.isSuccess) {
          const partnerCode = localStorage.getItem('selectedPartnerCode');
          const foundPartner = response.data.partnerlist.find(partner => partner.partnercode === partnerCode);
          setPartner(foundPartner);
        } else {
          setError('Failed to fetch partner details');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPartnerDetails();
  }, []);
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div style={{ backgroundColor: '#ffffff' }} className="main-content flex-1 bg-teal-100 overflow-auto p-4 rounded-md">
    <h1 className="text-5xl font-bold">Vendor List </h1>
      <div className="flex justify-between ml-4">
     <div className="p-4">
      {partner ? (
        <div className="bg-gray-100  shadow-md rounded p-4">
          <h2 className="text-xl font-bold mb-4">Partner Details</h2>
          <p><strong>Company Name:</strong> {partner.companyname}</p>
                 <p><strong>Discount:</strong> {partner.discount}%</p>
                 <p><strong>Partner Code:</strong>{partner.partnercode}</p>

        </div>
      ) : (
        <div>No partner found for the stored partner code.</div>
      )}
    </div>
    <div className="p-4">
      {partner ? (
        <div className="bg-gray-100  shadow-md rounded p-2">
          <h2 className="text-xl font-bold mb-4">Partner Information</h2>
      
          <p><strong>Contact Person:</strong> {partner.contactperson}</p>
          <p><strong>Balance:</strong> ${partner.balance}</p>

          <p><strong>Partner Type:</strong>{partner.role}</p>

        </div>
      ) : (
        <div>No partner found for the stored partner code.</div>
      )}
    </div>
</div>

      <div className="mt-4 p-4 flex justify-between items-center">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 text-center border-b">Name</th>
              <th className="py-2 px-4 text-center border-b">Status</th>
            </tr>
          </thead>
          <tbody>
            {vendors.map((vendor) => (
              <tr key={vendor.id}>
                <td className="py-2 px-4 text-center border-b">{vendor.name || "NA"}</td>
                <td className="py-2 px-4 text-center border-b">
                  <button
                    className={`rounded-full px-6 py-2 text-white shadow-md transition duration-200 ease-in ${
                      vendor.status === "1" ? "hover:bg-white" : ""
                    }`}
                    style={{
                      background:
                        vendor.status === "1"
                          ? "red"
                          : "linear-gradient(to bottom right, #27d0b6, #24c8b5, #1bb1af, #18a8ad)",
                      cursor: vendor.status === "1" ? "not-allowed" : "pointer",
                    }}
                    onClick={() => openModal(vendor)} // Open the modal on click
                  >
                    {vendor.status === "1" ? "Inactive" : "Active"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal for status update */}
      {modalOpen && selectedVendor && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-xl mb-4">Update Status for {selectedVendor.name}</h3>
            <div className="mb-4">
              <label className="block mb-2">Change Status</label>
              <select
                value={statusFlag}
                onChange={(e) => setStatusFlag(Number(e.target.value))}
                className="border rounded px-4 py-2"
              >
                <option value="1">Active</option>
                <option value="2">Inactive</option>
              </select>
            </div>

            {/* Show API message here */}
            {apiMessage && <div className="text-green-500 mb-4">{apiMessage}</div>}

            <div className="flex justify-end">
              <button
                onClick={() => setModalOpen(false)}
                className="px-4 py-2 bg-gray-400 text-white rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={updateStatus}
                className="px-4 py-2 bg-blue-500 text-white rounded"
              >
                Update Status
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VendorList;
